import React, { useState, useEffect } from "react";
import {
  AccountDTO,
  OverriddenAccountTypeValue,
} from "@dineiro/dineiro-sdk-mobile";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getBrandOfficesApi } from "../../services/api-service";
import { getLocalStorageElement } from "../../utils/utils";
import LoanAccountType from "../../components/liability/LoanAccountType";
import Button from "../../components/common/button/Button";
import ExportIcon from "../../images/export/loan-repayment.svg";

interface GroupedLoan {
  accountType: any;
  accounts: AccountDTO[];
}

const LiabilitiesTab: React.FC<{
  loans: AccountDTO[];
  setChangeTypes: React.Dispatch<React.SetStateAction<boolean>>;
  changeTypes: any;
}> = ({ loans, setChangeTypes, changeTypes }) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [groupedLoans, setGroupedLoans] = useState<GroupedLoan[]>([]);
  const [total, setTotal] = useState(0);
  const { officeid, tab, id, customername } = useParams();
  const groupLoans = () => {
    if (loans.length > 0) {
      const sortedAccounts = loans.sort((a, b) =>
        a.accountType.localeCompare(b.accountType)
      );

      const groupedAccounts = new Map<any, AccountDTO[]>();

      sortedAccounts.forEach((account) => {
        if (
          account.accountType === "CREDIT" ||
          account.accountType === "CHARGE"
        ) {
          groupedAccounts.has("credit_card")
            ? groupedAccounts.get("credit_card").push(account)
            : groupedAccounts.set("credit_card", [account]);
        } else {
          groupedAccounts.has(account.accountType)
            ? groupedAccounts.get(account.accountType).push(account)
            : groupedAccounts.set(account.accountType, [account]);
        }
      });
      let index = 0;
      const result = Array.from(groupedAccounts.entries()).map<GroupedLoan>(
        ([accountType, accountList]) => ({
          accountType,
          accounts: accountList,
        })
      );
      setGroupedLoans(result);
    }
  };

  const getTotal = () => {
    if (loans.length > 0) {
      const totalLoans = loans.reduce(
        (acc, obj) => acc + (obj.balance ? obj.balance?.amount! : 0),
        0
      );
      setTotal(totalLoans);
    }
  };

  const onChangeTypes = (type, accountId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersAccountsAccountTypePatch({
          id: parseInt(officeid),
          userId: id,
          accountId: accountId,
          brandOfficeCustomersAccountsAccountTypePatchViewModel: {
            accountType: OverriddenAccountTypeValue[type],
          },
        })
        .then((respone) => {
          setChangeTypes(!changeTypes);
        }),
      {
        loading: "Saving...",
        success: <b>Change account type successful.</b>,
        error: <b>Failed to change type.</b>,
      }
    );
  };

  const handleDownload = (downloadLink) => {
    const link = document.createElement("a");

    link.href = downloadLink;

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const getReportFile = (accountId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersAccountsLoanRepaymentReportGet({
          id: parseInt(officeid),
          userId: id,
          accountId: accountId,
        })
        .then((respone) => {
          handleDownload(respone.data);
        }),
      {
        loading: "Downloading...",
        success: <b>File downloaded successfully.</b>,
        error: <b>Failed to download file.</b>,
      }
    );
  };

  useEffect(() => {
    groupLoans();
    getTotal();
  }, [loans]);

  return (
    <>
      {groupedLoans && groupedLoans.length > 0 ? (
        <div className="my-10 text-[15px] grid grid-cols-12">
          <div className=" col-start-2 col-span-10">
            <div className="p-2">
              <div className="grid grid-cols-7 gap-2">
                <div className=" col-span-3"></div>

                <div className=" col-span-2 grid grid-cols-2 text-sm font-semibold gap-2">
                  <div>
                    <span>Account Type</span>
                  </div>
                  <div>
                    <span>Reports </span>
                  </div>
                </div>
                <div className=" col-span-2 grid grid-cols-2 text-sm font-semibold gap-2">
                  <div className="flex justify-end text-sm">
                    <span>Interest Rate</span>
                  </div>
                  <div className="flex justify-end ">
                    <span>Balance</span>
                  </div>
                </div>
              </div>
              {groupedLoans.map((loanGroup) => {
                return (
                  <div
                    className="mb-5 px-0"
                    key={groupedLoans.indexOf(loanGroup) + 1}
                  >
                    <div className="mb-2">
                      <span className=" uppercase font-semibold">
                        {loanGroup.accountType.replace(/_/g, " ")}(S)
                      </span>
                    </div>
                    {loanGroup.accounts.map((loan) => {
                      return (
                        <div
                          className="grid grid-cols-7 gap-2 items-center mb-1"
                          key={loan.id}
                        >
                          <div className=" col-span-3">
                            <span>{`${loan.providerName}-${loan.accountName} (
                            ${loan.accountNumber
                                ? loan.accountNumber.slice(-4)
                                : ""
                              }
                            )`}</span>
                          </div>
                          <div className=" col-span-2 grid grid-cols-2 text-sm font-semibold gap-2 items-center">
                            {loanGroup.accountType === "credit_card" ? (
                              <div className="min-w-[200px] col-span-1"></div>
                            ) : (
                              <LoanAccountType
                                container={loan.container}
                                accountType={loan.accountType ?? ""}
                                accountId={loan.id}
                                onChangeTypes={onChangeTypes}
                              ></LoanAccountType>
                            )}
                            {loanGroup.accountType === "credit_card" ? (
                              <div></div>
                            ) : (
                              <Button
                                w="150px"
                                h="40px"
                                px="18px"
                                borderRadius="0.375rem"
                                onClick={() => getReportFile(loan.id)}
                              >
                                <img src={ExportIcon} alt="" />
                                <span>Repayment</span>
                              </Button>
                            )}
                          </div>

                          <div className=" col-span-2 grid grid-cols-2 text-sm font-semibold gap-2">
                            <div className="flex justify-end text-sm">
                              {loanGroup.accountType === "credit_card" ? (
                                <span>
                                  {loan.totalCreditLimit
                                    ? `$-${loan.totalCreditLimit.amount}`
                                    : ""}
                                </span>
                              ) : (
                                <span>
                                  {loan.interestRate
                                    ? `${(loan.interestRate < 1 ? loan.interestRate * 100 : loan.interestRate).toFixed(2)}%`
                                    : ""}
                                </span>
                              )}
                            </div>
                            <div className="flex justify-end text-sm font-semibold">
                              <span>
                                {(loan.balance! && loan.balance.amount!
                                  ? loan.balance.amount
                                  : 0
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="mt-5 p-2">
              <li className="flex justify-between py-2 font-semibold border-t border-slate-400">
                <span>Total Liabilities</span>
                <span>
                  {total.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center my-10 text-[15px]">
          This user does not have any loans
        </div>
      )}
    </>
  );
};

export default LiabilitiesTab;
